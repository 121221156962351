<div class="container">
  <div class="row m-4 me-0 ms-0 align-items-center">
    <div class="col d-flex align-items-center" (click)="goBack()">
      <i class="fa-solid fa-arrow-left fa-2xl"></i>
    </div>
    <div class="col text-center">
      <a class="navbar-brand">
        <img src="assets/logos/pocketmartNavbar.png" alt="Logo" width="150" />
      </a>
    </div>
    <div class="col d-flex justify-content-end"></div>
  </div>
</div>
<hr />
<div class="container">
  <div class="card">
    <div class="card-header text-center">
      <div class="row">
        <div class="col-4">
          <img
            src="../../../assets/defaultImage/defaultimage.jpg"
            class="rounded-circle profile-img"
            alt="User Image"
          />
        </div>
        <div class="col">
          <p style="color: gray;"><b>ID : {{profileID}}</b></p>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <form>
        <!-- <div class="form-group">
          <label for="profileID">Profile ID</label>
          <input
            type="text"
            class="form-control"
            id="profileID"
            [value]="profileID"
            readonly
          />
        </div>-->
       
        <div class="card p-3 mt-0">
          <h5 style="margin-bottom: 0">User Details</h5>
          <div class="form-group mt-2">
            <label for="username">Name</label>
            <input
              type="text"
              class="form-control"
              id="username"
              [value]="meUnitName"
              readonly
            />
          </div>
          <!-- <div class="form-group mt-2">
            <label for="registeredDate">Registered Date</label>
            <input
              type="text"
              class="form-control"
              id="registeredDate"
              [value]="registeredDate | date : 'medium'"
              readonly
            />
          </div>  -->
  
          <div class="row">
            <div class="col">
              <div class="form-group mt-2">
                <label for="primaryMobileNumber">Phone Number</label>
                <input
                  type="tel"
                  class="form-control"
                  id="primaryMobileNumber"
                  [value]="phoneNumber"
                  readonly
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group mt-2">
                <label for="whatsAppNumber">WhatsApp</label>
                <input
                  type="tel"
                  class="form-control"
                  id="whatsAppNumber"
                  [value]="whatsAppNumber"
                  readonly
                />
              </div>
            </div>
          </div>

          <div class="form-group mt-2">
            <label for="meAppID">ME App ID</label>
            <input
              type="tel"
              class="form-control"
              id="meAppID"
              [value]="meAppID"
              readonly
            />
          </div>

          <div class="form-group mt-2">
            <label for="fssaiID">Fssai ID</label>
            <input
              type="tel"
              class="form-control"
              id="fssaiID"
              [value]="fssaiID"
              readonly
            />
          </div>

          <div class="form-group mt-2">
            <label for="gstNumber">GST Number</label>
            <input
              type="tel"
              class="form-control"
              id="gstNumber"
              [value]="gstNumber"
              readonly
            />
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group mt-2">
                <label for="cdsName">CDS Name</label>
                <input
                  type="tel"
                  class="form-control"
                  id="cdsName"
                  [value]="cdsName"
                  readonly
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group mt-2">
                <label for="cdsRegistrationNumber">CDS Number</label>
                <input
                  type="tel"
                  class="form-control"
                  id="cdsRegistrationNumber"
                  [value]="cdsRegistrationNumber"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card p-3">
          <h5 style="margin-bottom: 0">Bank Details</h5>
          <div class="row">
            <div class="col">
              <div class="form-group mt-2">
                <label for="bankName">Bank Name</label>
                <input
                  type="tel"
                  class="form-control"
                  id="bankName"
                  [value]="bankName"
                  readonly
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group mt-2">
                <label for="bankBranch">Bank Branch</label>
                <input
                  type="tel"
                  class="form-control"
                  id="bankBranch"
                  [value]="bankBranch"
                  readonly
                />
              </div>
            </div>
          </div>

          <div class="form-group mt-2">
            <label for="accountNumber">Account Number</label>
            <input
              type="tel"
              class="form-control"
              id="accountNumber"
              [value]="accountNumber"
              readonly
            />
          </div>

          <div class="form-group mt-2">
            <label for="ifscCode">IFSC Code</label>
            <input
              type="tel"
              class="form-control"
              id="ifscCode"
              [value]="ifscCode"
              readonly
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
