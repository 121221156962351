<div class="contianer">
  <div class="row pt-2 pb-2 header">
    <div class="col-2">
      <button type="button" class="btn btn-light" (click)="goBack()">
        <!--Using a Font Awesome icon-->
        <i class="fa-solid fa-arrow-left fa-xl"></i>
      </button>
    </div>
    <div class="col">
      <div class="div" style="text-align: center">
        <img
          class="logoImage"
          src="../../assets/logos/pocketmartNavbar.png"
          alt="Logo"
        />
      </div>
    </div>
    <div class="col-2"></div>
  </div>

  <div class="container">
    <div class="row mt-4">
      <div class="col pt-4 mt-4">
        <h3><u>Sales Report</u></h3>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Count</th>
              <th scope="col">Price</th>
            </tr>
          </thead>
          <tbody *ngFor="let lists of list">
            <tr>
              <td>{{ lists.productName }}</td>
              <td>{{ lists.productQuantity }}</td>
              <td>{{ lists.totalPrice }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<app-nav-bar></app-nav-bar>
