import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';


const BACKEND_URL = environment.kpServiceAPIUrl;
//SweetAlert2
import Swal from 'sweetalert2';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private router: Router,
    // private localStorage: LocalStorageService,
    // public pwaService: PwaService,
    private location: Location
  ) {
    this.checkPwaInstallationStatus();
  }

  isModalOpen: boolean = false;
  currentPassword: string = '';
  newPassword: string = '';

  // Function to check if the PWA is installed
  checkPwaInstallationStatus() {
    // const mediaQueryList = window.matchMedia('(display-mode: standalone)');
    // console.log('Media query matches:', mediaQueryList.matches); // Debug line
    // this.isPwaInstalled = mediaQueryList.matches;
    // console.log('Check PWA installation status:', this.isPwaInstalled);
  }
  ngOnInit(): void {}

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
    this.currentPassword = '';
    this.newPassword = '';
  }

  savePassword() {
   
    if (this.currentPassword && this.newPassword) {
      // Implement the logic for saving the password here
      const params = new HttpParams()
      .set('currentPassword', this.currentPassword)
      .set('newKeyword', this.newPassword);

    this.http.put(BACKEND_URL + '/sellerPasswordRest', {}, { params }).subscribe(
        (response: any) => {
          const responseMessage = response.message;
          console.log(response.message);
          Swal.fire({
            icon: 'success',
            title: 'Password changed Successful',
            confirmButtonText: 'OK'
          });
        },
        (error) => {
          const responseMessage = 'Error resetting password';
          console.error('Error:', error);
          Swal.fire({
            icon: 'error',
            title: 'error in changing password',
            confirmButtonText: 'OK'
          });
        }
      );
      console.log('Current Password:', this.currentPassword);
      console.log('New Password:', this.newPassword);
      this.closeModal();
    } else {
      alert('Please enter both passwords.');
    }
  }


  goBack() {
    this.location.back();
  }
  goToProfile() {
    this.router.navigate(['/profile-details']);
  }

  goToPickUpLocation(){
    this.router.navigate(['/pickUpLocation']);
  }

  goToSalesReport(){
    this.router.navigate(['/saleReport']);
  }


  logOut() {
    Swal.fire({
      title: 'Custom Alert',
      text: 'Do you want to logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, proceed!',
      cancelButtonText: 'No, cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('token');
        this.router.navigate(['/login']);
      } else if (result.isDismissed) {
        Swal.fire('Cancelled', 'Your action was cancelled.', 'error');
      }
    });
  }

  deleteAccount() {
    Swal.fire({
      title: 'Custom Alert',
      text: 'Do you want to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, proceed!',
      cancelButtonText: 'No, cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteSellerAccount();
      } else if (result.isDismissed) {
        Swal.fire('Cancelled', 'Your action was cancelled.', 'error');
      }
    });
  }

  deleteSellerAccount() {
    this.http.put(BACKEND_URL + '/sellerDeleteAccount', {}).subscribe(
      (data: any) => {
        Swal.fire('Success!', 'account deleted successfully.', 'success');
        localStorage.removeItem('token');
        this.router.navigate(['/login']);
      },
      (error) => {
        console.log(error);
        Swal.fire(
          'Error!',
          'There was a problem in deleting your account.',
          'error'
        );
      }
    );
  }

  changePassword() {}
}
