<div class="container-fluid pt-3">
  <div class="row pt-2 pb-2 header">
    <div class="col-2">
      <button type="button" class="btn btn-light" (click)="goBack()">
        <!--Using a Font Awesome icon-->
        <i class="fa-solid fa-arrow-left fa-xl"></i>
      </button>
    </div>
    <div class="col">
      <div class="div" style="text-align: center">
        <img
          class="logoImage"
          src="../../assets/logos/pocketmartNavbar.png"
          alt="Logo"
        />
      </div>
    </div>
    <div class="col-2"></div>
  </div>
  <hr style="margin-top: 10px; margin-bottom: 0" />
</div>

<br />

<div class="container mainBody pt-4">
  <br>
  <div class="container card pt-3 pb-3">
    <div class="row">
      <div class="col">
        <label>Pickup Location</label>
        <input
          type="text"
          #inputField
          [placeholder]="placeholder"
          class="form-control"
          maxlength="100"
        />
        
      </div>
    </div>

    <!-- Add Google Maps container here -->
    <div class="row mt-4">
      <div class="col">
        <div #mapContainer id="map" style="height: 300px; width: 100%;"></div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <div style="float: right;" 
        *ngIf="spinner"
        class="spinner-border text-success" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button *ngIf="!spinner" (click)="savePickUpLocation()"
        style="float: right; margin-left: 10px;" type="button" class="btn btn-success">Submit</button>
        <button 
        style="float: right; background-color: rgb(158, 64, 158); border: none"
        (click)="useCurrentLocation()" 
        class="btn btn-primary">
        <i class="fas fa-crosshairs"></i> Use Current Location
    </button>
    
       
      </div>
    </div>
  </div>
</div>


 

<app-nav-bar></app-nav-bar>
