import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';
const BACKEND_URL = environment.kpServiceAPIUrl;
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.css'],
})
export class SalesReportComponent implements OnInit {
  constructor(
    private location: Location,
    private router: Router,
    private http: HttpClient, // private router: Router, // private http: HttpClient,
    private activatedRoute: ActivatedRoute // private route: ActivatedRoute, // private location: Location, // private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.fetchSalesReport();
  }
  goBack() {
    this.location.back();
  }

  list: any;

  fetchSalesReport() {
    this.http.get(`${BACKEND_URL}/kpProductsSalesReports`).subscribe(
      (response: any) => {
        console.log('response : ', response);
        this.list = response;
      },
      (error) => {
        console.error('Error fetching order details:', error);
      }
    );
  }
}
