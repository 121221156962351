import { Component, OnInit, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ListingService } from './listing.service';
import Swal from 'sweetalert2';

const BACKEND_URL = environment.kpServiceAPIUrl;
@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.css'],
})
export class ListingComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  productTitle: string = '';
  productDescription: string = '';
  price: number|undefined;
  viewNumber: number|undefined;
  productID: string = '';
  list: any;
  isModalOpen: boolean = false;
  quantity: number | null = null;
  spinner: boolean = false;
  listType: string = '';

// For Active Products
currentPageActive: number = 1;
totalPagesActive: number = 0;
totalActiveProducts: number = 0;
limitActive: number = 20;

// For Inactive Products
currentPageInactive: number = 1;
totalPagesInactive: number = 0;
totalInactiveProducts: number = 0;
limitInactive: number = 20;

  ngOnInit(): void {
    this.active();
    // Scroll to the top of the page when component initializes
    window.scrollTo(0, 0);
  }

  isActive: boolean = true;



 
  limitDigits(event: any) {
    // Get the input value
    const value = event.target.value;

    // Remove non-digit characters
    const filteredValue = value.replace(/\D/g, '');

    // Limit the length to 3 digits
    if (filteredValue.length > 3) {
      event.target.value = filteredValue.slice(0, 3);
    } else {
      event.target.value = filteredValue;
    }

    // Update the component's model value
    this.quantity = event.target.value ? Number(event.target.value) : null;
  }

  closeModal() {
    this.isModalOpen = false;
  }

  active() {
    this.isActive = true;
    this.listType = 'active';
    this.fetchActiveProducts(this.currentPageActive, this.limitActive);
  }


  inActive() {
    this.isActive = false;
    this.listType = 'inactive';
    this.fetchInactiveProducts(this.currentPageInactive, this.limitInactive);
  }

  addSale(productId: string, productName: string, sellingPrice: number) {
    this.productTitle = productName;
    this.price = sellingPrice;
    this.productID = productId;
    this.isModalOpen = true;
  }

  editProduct(productID: string) {
    this.router.navigate(['/postItem'],{queryParams:{productID:productID}})
  }

  // fetchActiveProducts() {
  //   this.spinner = true;
  //   this.http.get(`${BACKEND_URL}/instock/kpProductsListingPage`).subscribe(
  //     (response: any) => {
  //       console.log('response : ', response);
  //       this.list = response;
  //       this.spinner = false;
  //     },
  //     (error) => {
  //       console.error('Error fetching order details:', error);
  //       this.spinner = false;
  //     }
  //   );
  // }

  fetchActiveProducts(page: number = 1, limit: number = 20) {
    this.spinner = true;
    this.http
      .get(`${BACKEND_URL}/instock/kpProductsListingPage?page=${page}&limit=${limit}`)
      .subscribe(
        (response: any) => {
          console.log('response:', response);
          this.list = response.products; // Use the paginated products
          this.totalActiveProducts = response.totalProducts;
          this.currentPageActive = response.currentPage;
          this.totalPagesActive = response.totalPages;
          this.spinner = false;
        },
        (error) => {
          console.error('Error fetching active products:', error);
          this.spinner = false;
        }
      );
  }
  


  // fetchInactiveProducts() {
  //   this.spinner = true;
  //   this.http.get(`${BACKEND_URL}/outofstock/kpProductsListingPage`).subscribe(
  //     (response: any) => {
  //       console.log('response : ', response);
  //       this.list = response;
  //       this.spinner = false;
  //     },
  //     (error) => {
  //       console.error('Error fetching order details:', error);
  //       this.spinner = false;
  //     }
  //   );
  // }

  fetchInactiveProducts(page: number = 1, limit: number = 20) {
    this.spinner = true;
    this.http
      .get(`${BACKEND_URL}/outofstock/kpProductsListingPage?page=${page}&limit=${limit}`)
      .subscribe(
        (response: any) => {
          console.log('Response:', response);
          this.list = response.products; // Assign the paginated products to the list
          this.totalInactiveProducts = response.totalProducts; // Total products available
          this.currentPageInactive = response.currentPage; // Current page number
          this.totalPagesInactive = response.totalPages; // Total number of pages
          this.spinner = false;
        },
        (error) => {
          console.error('Error fetching inactive products:', error);
          this.spinner = false;
        }
      );
  }
  

  previousPageInactive() {
    if (this.currentPageInactive > 1) {
      this.fetchInactiveProducts(this.currentPageInactive - 1, this.limitInactive);
    }
  }
  
  nextPageInactive() {
    if (this.currentPageInactive < this.totalPagesInactive) {
      this.fetchInactiveProducts(this.currentPageInactive + 1, this.limitInactive);
    }
  }

  previousPageActive() {
    if (this.currentPageActive > 1) {
      this.fetchActiveProducts(this.currentPageActive - 1, this.limitActive);
    }
  }
  
  nextPageActive() {
    if (this.currentPageActive < this.totalPagesActive) {
      this.fetchActiveProducts(this.currentPageActive + 1, this.limitActive);
    }
  }

  toAddSale() {
    const orderID = 'OR' + new Date().getTime().toString().substr(3, 10);
    
    const totalPrice = (this.price ?? 0) * (this.quantity ?? 0);
    this.isModalOpen = false
    if (!this.quantity) {
      Swal.fire({
        icon: 'warning',
        title: 'Quantity Missing',
        text: 'Please enter the quantity value.',
        confirmButtonText: 'OK',
      });
      return;
    }

    const saleData = {
      quantity: this.quantity,
      productID: this.productID,
      productTitle: this.productTitle,
      price: this.price,
      totalPrice: totalPrice,
      orderID:orderID
    };

    // POST request directly in the component
    this.http.post<any>(`${BACKEND_URL}/sellerAddOrder`, saleData).subscribe({
      next: (response) => {
        Swal.fire({
          icon: 'success',
          title: 'Sale Added',
          text: 'The sale has been added successfully.',
          confirmButtonText: 'OK',
        });
      },
      error: (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'There was an issue adding the sale.',
          confirmButtonText: 'OK',
        });
      },
    });
  }


  isProductRejected(rejectStatus: string): boolean {
    return ['stateReject', 'districtReject', 'marketingReject'].includes(rejectStatus);
  }

  getReadableStatus(status: string): string {
    switch (status) {
      case 'districtReject':
        return 'Rejected by District Admin';
      case 'stateReject':
        return 'Rejected by State Admin';
      case 'marketingReject':
        return 'Rejected by Marketing Admin';
      case 'districtPending':
        return 'Pending District Admin Approval';
      case 'statePending':
        return 'Pending State Admin Approval';
      case 'marketingPending':
        return 'Pending Marketing Admin Approval';
      default:
        return status;
    }
  }

}
