<div class="container fullPage">
  <div class="container p-3 align-items-center">
    <div class="container">
      <h3 class="pt-4 welcomeText">Welcome to</h3>

      <div class="div" style="text-align: center">
        <img
          class="logoImage"
          src="../../assets/logos/pocketmartNavbar.png"
          alt="Logo"
        />
      </div>

      <h2 class="sellerApp">Seller App</h2>
    </div>
    <div class="row align-items-center">
      <div class="col text-center">
        <img class="logos" src="assets/logos/image.png" alt="" />
      </div>
    </div>

    <div class="container">
      <div>
        <label class="form-label"> <b> Enter your phone number </b></label>
      </div>
      <div class="row">
        <div class="col p-0">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="phoneNumber"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Please enter your phone number here..."
            required
            pattern="^\d{10}$"
            maxlength="10"
            inputmode="numeric"
            title="Please enter a valid 10-digit phone number"
            oninput="this.value = this.value.replace(/[^0-9]/g, ''); if (this.value.length > 10) this.value = this.value.slice(0, 10); "
          />
          <p *ngIf="phoneNumber.length < 10" style="color: rgb(209, 52, 52)">
            Phone number must be 10 digits!
          </p>
        </div>

        <div class="container inputKeyWord pt-2">
          <div class="row">
            <label class="form-label">
             <b>Enter your password</b> 
            </label>
          </div>
          <div class="row align-items-center">
            <div class="col p-0">
              <input
                type="text"
                maxlength="20"
                class="form-control"
                [(ngModel)]="keyword"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Please enter your password here..."
                required
              />
            </div>
          </div>
        </div>

        <div class="container agreeContinue">
          <div class="row">
            <button
           
              type="submit"
              class="btn btn-success"
              (click)="login()"
            >
              LOGIN
            </button>

            <!-- <div class="spinner-border" *ngIf="spinner" role="status">
              <span class="visually-hidden">Loading...</span>
            </div> -->
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
