import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModel } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule, JwtHelperService } from '@auth0/angular-jwt';
import { AuthInterceptor } from './auth.interceptor';
import { DatePipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { DescriptionComponent } from './components/description/description.component';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { ProfileDetailsComponent } from './components/profile-details/profile-details.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ListingComponent } from './listing/listing.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { SettingsComponent } from './settings/settings.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PostItemComponent } from './post-item/post-item.component';
import { PickUpLocationComponent } from './pick-up-location/pick-up-location.component';
import { SalesReportComponent } from './sales-report/sales-report.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    DescriptionComponent,
    ProfilePageComponent,
    ProfileDetailsComponent,
    ListingComponent,
    NavBarComponent,
    SettingsComponent,
    DeleteAccountComponent,
    ContactUsComponent,
    AboutUsComponent,
    PostItemComponent,
    PickUpLocationComponent,
    SalesReportComponent,
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
   
    FormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem('token'); // Provide a function to retrieve the token from local storage
        }
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    
  ],
  providers: [DatePipe,JwtHelperService,
    // Provide the interceptor
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
