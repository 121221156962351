import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { QsIdsService } from '../../services/qs-ids.service';

const BACKEND_URL = environment.kpServiceAPIUrl;

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.css'],
})
export class ProfileDetailsComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private QsIdsService: QsIdsService
  ) {}

  profileDetails: any;
  profileID: any;
  meUnitName: any;
  registeredDate: any;
  phoneNumber: any;
  whatsAppNumber: any;
  meAppID: any;
  fssaiID: any;
  gstNumber: any;
  cdsName: any;
  cdsRegistrationNumber: any;
  bankName: any;
  bankBranch: any;
  accountNumber: any;
  ifscCode: any;

  localBodyType: any;
  localBodyName: any;
  wardNumber: any;
  wardName: any;
  districtName: any;
  idProofName: any;
  idProofNumber: any;
  idProofNamePath: any;

  primaryMobileNumber: any;

  serviceName: any;
  active: any;
  delete: any;
  experience: any;

  ngOnInit() {
    this.fetchOrderDetails();
  }

  goBack() {
    this.location.back();
  }

  fetchOrderDetails() {
    // Make a GET request to fetch order details
    this.http.get(`${BACKEND_URL}/kpSellerProfilePage`).subscribe(
      (response: any) => {
        console.log('response : ', response);
        this.profileDetails = response[0];
        this.profileID = this.profileDetails.profileID;
        this.meUnitName = this.profileDetails.meUnitName;
        this.registeredDate = this.profileDetails.registeredDate;
        this.phoneNumber = this.profileDetails.phoneNumber;
        this.whatsAppNumber = this.profileDetails.whatsappNumber;
        this.meAppID = this.profileDetails.meAppID;
        this.fssaiID = this.profileDetails.fssaiID;
        this.gstNumber = this.profileDetails.gstNumber;
        this.cdsName = this.profileDetails.cdsName;
        this.cdsRegistrationNumber = this.profileDetails.cdsRegistrationNumber;
        this.bankName = this.profileDetails.bankDetails.bankName;
        this.bankBranch = this.profileDetails.bankDetails.bankBranch;
        this.accountNumber = this.profileDetails.bankDetails.accountNumber;
        this.ifscCode = this.profileDetails.bankDetails.ifscCode;
        console.log('this.profileID : ', this.profileID);
        console.log('this.registeredDate : ', this.registeredDate);
        console.log('this.fssaiID : ', this.fssaiID);
        console.log('this.bankName : ', this.bankName);
        // this.assignDetails(this.profileDetails[0]);
      },
      (error) => {
        console.error('Error fetching order details:', error);
        // Handle error, show an error message, etc.
      }
    );
  }

  // assignDetails(details: any): void {
  //   const {
  //     applicationDetails: {
  //       localBodyType,
  //       localBodyName,
  //       wardNumber,
  //       wardName,
  //       districtName,
  //       idProofName,
  //       idProofNumber,
  //       idProofNamePath,
  //     },
  //     profileID,
  //     username,
  //     primaryMobileNumber,
  //     secondaryMobileNumber,
  //     whatsAppNumber,
  //     serviceName,
  //     active,
  //     delete: isDelete,
  //     experience,
  //     registeredDate,
  //   } = details;

  //   this.localBodyType = localBodyType;
  //   this.localBodyName = localBodyName;
  //   this.wardNumber = wardNumber;
  //   this.wardName = wardName;
  //   this.districtName = districtName;
  //   this.idProofName = idProofName;
  //   this.idProofNumber = idProofNumber;
  //   this.idProofNamePath = idProofNamePath;
  //   this.profileID = profileID;
  //   this.username = username;
  //   this.primaryMobileNumber = primaryMobileNumber;
  //   this.secondaryMobileNumber = secondaryMobileNumber;
  //   this.whatsAppNumber = whatsAppNumber;
  //   this.serviceName = serviceName;
  //   this.active = active;
  //   this.delete = isDelete;
  //   this.experience = experience;
  //   this.registeredDate = new Date(registeredDate);
  // }
}
