<div class="container-fluid pt-3">
  <div class="row pt-2 pb-2  header">
    <div class="col-2">
      <button type="button" class="btn btn-light" (click)="goBack()">
        <!--Using a Font Awesome icon-->
        <i class="fa-solid fa-arrow-left fa-xl"></i>
      </button>
    </div>
    <div class="col">
      <div class="div" style="text-align: center">
        <img
          class="logoImage"
          src="../../assets/logos/pocketmartNavbar.png"
          alt="Logo"
        />
      </div>
    </div>
    <div class="col-2"></div>
  </div>
  <hr style="margin-top: 10px; margin-bottom: 0" />
</div>
<br />

<!--setting page contents-->
<div class="container mainBody pt-2">
  <!--modal starts-->
  <div *ngIf="isModalOpen" class="custom-modal">
    <div class="custom-modal-content">
      <span class="close" (click)="closeModal()">&times;</span>
      <h2>Change Password</h2>

      <form>
        <div class="form-group">
          <label for="exampleInputEmail1">Current Password</label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Current Password"
            maxlength="20"
            [(ngModel)]="currentPassword"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">New Password</label>
          <input
            type="text"
            class="form-control"
            id="exampleInputPassword1"
            placeholder="New Password"
            maxlength="20"
            [(ngModel)]="newPassword"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <button type="submit" (click)="savePassword()" class="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  </div>
  <!--modal ends-->

  <div class="container">
    <div class="row">
      <div class="col">
        <p class="Settings">Settings</p>
        <p style="color: gray">Account Information</p>
      </div>
    </div>
  </div>

  <div class="container card p-3">
    <div class="row">
      <div class="col">
        <p class="account">My Account</p>
      </div>
    </div>

    <div class="row pt-2">
      <div class="col">
        <button
          type="button"
          class="btn btn-primary profileButton"
          (click)="goToProfile()"
        >
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div style="display: flex; align-items: center">
              <i class="fa-solid fa-user" style="color: #000000"></i>
              <span class="profileIcon" style="margin-left: 10px"
                ><b>Profile</b></span
              >
            </div>
            <i class="fa-solid fa-angle-right" style="color: #000000"></i>
          </div>
        </button>
      </div>
    </div>

    


    <div class="row">
      <div class="col">
        <button
          type="button"
          class="btn btn-primary profileButton"
          (click)="goToSalesReport()"
        >
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div style="display: flex; align-items: center">
              <i class="fa-solid fa-location-crosshairs" style="color: #000000"></i>
              <span class="profileIcon" style="margin-left: 10px"
                ><b>Sales Report</b></span
              >
            </div>
            <i class="fa-solid fa-angle-right" style="color: #000000"></i>
          </div>
        </button>
      </div>
    </div>


    <div class="row">
      <div class="col">
        <button
          type="button"
          class="btn btn-primary profileButton"
          (click)="goToPickUpLocation()"
        >
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div style="display: flex; align-items: center">
              <i class="fa-solid fa-location-crosshairs" style="color: #000000"></i>
              <span class="profileIcon" style="margin-left: 10px"
                ><b>Add PickUp Location</b></span
              >
            </div>
            <i class="fa-solid fa-angle-right" style="color: #000000"></i>
          </div>
        </button>
      </div>
    </div>


    <div class="row">
      <div class="col">
        <button
        (click)="openModal()"
        type="button"
        class="btn btn-primary profileButton"
      >
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <div style="display: flex; align-items: center;">
            <i class="fa-solid fa-pen-to-square" style="color: #000000;"></i>
            <span class="profileIcon" style="margin-left: 10px;"><b>Change Password</b></span>
          </div>
          <i class="fa-solid fa-angle-right" style="color: #000000;"></i>
        </div>
      </button>
      </div>
    </div>
    
  </div>
  <!-- <div class="container mt-4 p-3 card">
    <div class="row">
      <div class="col">
        <button
          type="button"
          class="btn btn-primary profileButton"
          (click)="deleteAccount()"
        >
          <div style="display: flex; align-items: center">
            <i class="fa-solid fa-trash" style="color: red"></i>
            <span class="profileIcon" style="color: red">
              <b>Delete Account </b></span
            >
          </div>
        </button>
      </div>
    </div>
  </div> -->

    <div class="container mt-4 p-3 card">
      <div class="row">
        <div class="col">
          <button
            type="button"
            class="btn btn-primary profileButton"
            (click)="logOut()"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div style="display: flex; align-items: center">
                <i
                  class="fa-solid fa-right-from-bracket"
                  style="color: #ff0000"
                ></i>
                <span class="profileIcon" style="margin-left: 10px; color: red"
                  ><b>Logout</b></span
                >
              </div>
              <i class="fa-solid fa-angle-right" style="color: #fd0000"></i>
            </div>
          </button>
        </div>
      </div>
  </div>
</div>

<app-nav-bar></app-nav-bar>
