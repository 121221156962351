<div class="container-fluid pt-2">
  <div class="row">
    <nav class="nav p-0 pt-2">
      <div class="col">
        <a
          class="nav__link"
          routerLink="/home"
          routerLinkActive="active"
          (click)="home()"
        >
          <i class="fa fa-home fa-2x"></i>
          <span class="nav__text">Home</span>
        </a>
      </div>

      <div class="col d-flex justify-content-center align-items-center">
        <div class="container-grid">
          <div class="grid-column">
            <button
            (click)="goToPostPage()"
              type="button"
              class="btn btn-success rounded-circle postButton"
            >
              <div class="col d-flex justify-content-center">Post</div>
            </button>
          </div>
        </div>
      </div>

      <div class="col p-0">
        <a
          class="nav__link"
          routerLinkActive="active"
          routerLink="/settings"
          (click)="goToSettings()"
        >
          <i class="fa fa-gear fa-2x"></i>
          <span class="nav__text">Settings</span>
        </a>
      </div>
      
    </nav>
  </div>
</div>
