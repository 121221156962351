import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  AfterViewInit,
  Output,
  OnInit,
  EventEmitter,
  NgZone,
} from '@angular/core';

export interface PlaceSearchResult {
  address: string;
  location?: { lat: number; lng: number };
  imageUrl?: string;
  iconUrl?: string;
  name?: string;
}

import Swal from 'sweetalert2';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';

const GOOGLE_API = environment.googleMapsAPIKey;
const BACKEND_URL = environment.kpServiceAPIUrl;

@Component({
  selector: 'app-pick-up-location',
  templateUrl: './pick-up-location.component.html',
  styleUrls: ['./pick-up-location.component.css'],
})
export class PickUpLocationComponent implements AfterViewInit, OnInit {
  @ViewChild('inputField') inputField!: ElementRef;
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;

  @Input() placeholder = 'Enter pickup location';

  @Output() placeChanged = new EventEmitter<PlaceSearchResult>();

  autocomplete: google.maps.places.Autocomplete | any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private ngZone: NgZone,
    private location: Location
  ) {}

  //to get the pickup location
  getPickUpLocation() {
    this.http
      .get<{
        pickupLocation: any;
      }>(`${BACKEND_URL}/getPickupLocation`)
      .subscribe(
        (response) => {
          console.log(response);
          const pickupLocation = response.pickupLocation;

          // Handle the pickupLocation data
          console.log('Pickup Location:', pickupLocation);
          if (
            pickupLocation &&
            pickupLocation.location &&
            pickupLocation.location.coordinates
          ) {
            this.latitude = pickupLocation.location.coordinates[1];
            this.longitude = pickupLocation.location.coordinates[0];

            console.log(this.defaultLocation);
            const coordinates = pickupLocation.location.coordinates;
            console.log(pickupLocation, 'dsa');
            if (Array.isArray(coordinates) && coordinates.length === 2) {
              console.log('Pickup Location');
              // Swal.fire({
              //   icon: 'success',
              //   title: 'Pickup Location Retrieved',
              //   text: `Address: ${pickupLocation.address}, Coordinates: ${coordinates.join(', ')}`,
              // });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Warning',
                text: 'Invalid coordinates format',
              }).then(() => {
                // Navigate to the pickupLocation page after the SweetAlert closes
                this.router.navigate(['/pickUpLocation']);
              });
            }
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Pickup location or coordinates not found',
            }).then(() => {
              // Navigate to the pickupLocation page after the SweetAlert closes
              this.router.navigate(['/pickUpLocation']);
            });
          }
          // Now that we have the location, initialize the map
          this.initializeMap();
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error retrieving pickup location',
          }).then(() => {
            // Navigate to the pickupLocation page after the SweetAlert closes
            this.router.navigate(['/pickUpLocation']);
          });
          console.error('Error:', error);
        }
      );
  }
  ngOnInit(): void {
    this.getPickUpLocation();
  }

  address: any;
  latitude: any = 8.52426;
  longitude: any = 76.93664;
  spinner: boolean = false;

  // maps
  map!: google.maps.Map;
  marker!: google.maps.Marker;
  defaultLocation: any;

  initializeMap(): void {
    this.defaultLocation = { lat: this.latitude, lng: this.longitude };

    // Initialize the map
    this.map = new google.maps.Map(this.mapContainer.nativeElement, {
      center: this.defaultLocation,
      zoom: 15, // You may want to zoom in closer if it's a specific location
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
    });

    // Initialize the marker
    this.marker = new google.maps.Marker({
      position: this.defaultLocation,
      map: this.map,
      draggable: true,
      title: 'Selected Location',
    });

    // Add listeners for map clicks and marker dragging
    this.addMapListeners();
  }

  addMapListeners(): void {
    // Listener for map clicks to update the marker and coordinates
    this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        this.latitude = event.latLng.lat();
        this.longitude = event.latLng.lng();

        // Move the marker to the clicked location
        this.marker.setPosition(event.latLng);

        // Optionally, you can update the address by reverse geocoding
        this.geocodeLatLng(event.latLng);

        console.log(
          'Map clicked at',
          'Lat:',
          this.latitude,
          'Lng:',
          this.longitude
        );
      }
    });

    // Listener for dragging the marker to update coordinates
    this.marker.addListener('dragend', (event: google.maps.MapMouseEvent) => {
      this.latitude = event.latLng?.lat();
      this.longitude = event.latLng?.lng();

      // Optionally, update the address by reverse geocoding
      this.geocodeLatLng(event.latLng);

      console.log(
        'Marker dragged to',
        'Lat:',
        this.latitude,
        'Lng:',
        this.longitude
      );
    });
  }

  ngAfterViewInit(): void {
    // Initialize Autocomplete
    this.autocomplete = new google.maps.places.Autocomplete(
      this.inputField.nativeElement
    );

    // Set component restrictions to India
    this.autocomplete.setComponentRestrictions({ country: ['IN'] });

    // Listener for place selection
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();

      if (place.geometry && place.geometry.location) {
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        this.address = place.formatted_address;

        // Center the map on the selected location
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(15);

        // Move the marker to the new location
        this.marker.setPosition(place.geometry.location);

        // Log for debugging
        console.log('Selected place:', place);
        console.log(
          'Address:',
          this.address,
          'Lat:',
          this.latitude,
          'Lng:',
          this.longitude
        );
      }
    });

  
  }

  geocodeLatLng(latLng: google.maps.LatLng) {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK' && results[0]) {
        this.address = results[0].formatted_address;
        console.log('Reverse geocoded address:', this.address);
      } else {
        console.error('Geocode failed:', status);
      }
    });
  }

  useCurrentLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          // Update the map and marker with the current location
          this.map.setCenter(currentLocation);
          this.map.setZoom(15);
          this.marker.setPosition(currentLocation);

          this.latitude = currentLocation.lat;
          this.longitude = currentLocation.lng;

          // Reverse geocode to get the address
          this.geocodeLatLng(
            new google.maps.LatLng(currentLocation.lat, currentLocation.lng)
          );

          console.log('Current location:', currentLocation);
        },
        (error) => {
          console.error('Error getting current location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  getPhotoUrl(
    place: google.maps.places.PlaceResult | undefined
  ): string | undefined {
    return place?.photos && place.photos.length > 0
      ? place.photos[0].getUrl({ maxWidth: 300 })
      : undefined;
  }

  goBack() {
    this.location.back();
  }

  savePickUpLocation() {
    if (this.latitude && this.longitude) {
      this.spinner = true;
      const params = new HttpParams()
        .set('address', this.address)
        .set('latitude', this.latitude)
        .set('longitude', this.longitude);

      this.http
        .put(BACKEND_URL + '/addPickupLocation', {}, { params })
        .subscribe(
          (response: any) => {
            const responseMessage = response.message;
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: responseMessage,
            });
            this.spinner = false;
            this.goBack();
          },
          (error) => {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Error resetting password',
            });
            this.spinner = false;
            console.error('Error:', error);
          }
        );
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please add a location',
      });
    }
  }
}
