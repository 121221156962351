import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
const BACKEND_URL = environment.kpServiceAPIUrl;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  constructor(private router: Router, private http: HttpClient) {}

  phoneNumber: any = null;
  keyword: any = null;
  errorMessage: any;
  spinner: boolean = false;

  login(): void {
    if (!this.phoneNumber) {
      Swal.fire({
        icon: 'error',
        title: 'Please enter your phone number',
        text: 'Enter the phone number',
      });
      return;
    }
    if (!this.keyword) {
      Swal.fire({
        icon: 'error',
        title: 'Please enter your password',
        text: 'Enter the password',
      });

      return;
    }

    this.spinner = true;

    this.http
      .post<any>(`${BACKEND_URL}/kpLogin`, {
        phoneNumber: this.phoneNumber,
        keyword: this.keyword,
      })
      .subscribe(
        (response) => {
          if (response.token) {
            console.log(response);
            localStorage.setItem('token', response.token);
            localStorage.setItem('phoneNumber', this.phoneNumber);
            this.clearFields();
            this.router.navigate(['/listing']);
            this.spinner = false;
          } else {
            this.errorMessage = 'Invalid phone number or keyword';
            this.spinner = false;
            alert('User not found');
          }
        },
        (error) => {
          console.error('Error logging in:', error);
          if (error.status === 401) {
            // Show SweetAlert for invalid password
            Swal.fire({
              icon: 'error',
              title: 'Invalid Keyword',
              text: 'Please check your phone number and keyword and try again',
            });
          } else {
            this.errorMessage = 'An error occurred while logging in';
            Swal.fire({
              icon: 'error',
              title: 'An error occured',
              text: 'An error occured please try again',
            });
            this.spinner = false;
          }
        }
      );
  }

  clearFields() {
    this.phoneNumber = null;
    this.keyword = null;
  }
}
