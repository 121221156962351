import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { DescriptionComponent } from './components/description/description.component';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { ProfileDetailsComponent } from './components/profile-details/profile-details.component';
import { AuthGuard } from './auth.guard';
import { ListingComponent } from './listing/listing.component';
import { PostItemComponent } from './post-item/post-item.component';
import { SettingsComponent } from './settings/settings.component';
import { PickUpLocationComponent } from './pick-up-location/pick-up-location.component';
import { SalesReportComponent } from './sales-report/sales-report.component';
const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: 'description',
    component: DescriptionComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'saleReport',
    component: SalesReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: ProfilePageComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'pickUpLocation',
    component: PickUpLocationComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'profile-details',
    component: ProfileDetailsComponent,
    canActivate: [AuthGuard],
  },
  { path: 'listing', component: ListingComponent, canActivate: [AuthGuard] },

  { path: 'postItem', component: PostItemComponent },

  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
