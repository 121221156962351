import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DatePipe, NumberSymbol } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { QsIdsService } from '../../services/qs-ids.service';
import Swal from 'sweetalert2';

const BACKEND_URL = environment.kpServiceAPIUrl;

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.css'],
})
export class ProfilePageComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private QsIdsService: QsIdsService
  ) {}

  showQRcode: boolean = false;
  phoneNumber: any = null;
  QRCode: any = null;

  ngOnInit() {
    const phoneNumber = localStorage.getItem('phoneNumber');
    this.phoneNumber = phoneNumber;
    this.searchForServiceProvider();
  }

  goBack() {
    this.location.back();
  }

  goToProfilePageDetails() {
    this.router.navigate(['/profile-details']);
  }

  logout() {
    // Display SweetAlert confirmation dialog
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will be logged out',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout',
    }).then((result) => {
      if (result.isConfirmed) {
        // Clear local storage
        localStorage.clear();

        // Navigate to login page (assuming your login page route is '/login')
        this.router.navigate(['/login']);
      }
    });
  }

  getQRcode() {
    this.http
      .get(`${BACKEND_URL}/getQRcodeForServiceProvider/${this.phoneNumber}`)
      .subscribe(
        (response: any) => {
          console.log('response : ', response);
          this.QRCode = response.QRcode[0].QRCodeImageUrl;

          this.showQRcode = true;
        },
        (error) => {
          console.error('Error fetching order details:', error);
          // Handle error, show an error message, etc.
        }
      );
  }

  totalAmount: number = 0;
  completedServices: number = 0;
  searchForServiceProvider() {
    this.http
      .get<any>(BACKEND_URL + '/serviceProviderServiceCompleted')
      .subscribe(
        (data: any) => {
          console.log('the total amount for:', data);
          this.totalAmount = data[0]?.amountForServiceProvider;
          this.completedServices = data[0]?.completedOrderCount;
        },
        (error) => {
          console.error('Error', error);
          // Handle error response
        }
      );
  }
}
