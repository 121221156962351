<div class="container p-0 mt-3">
  <div class="container pt-2 pb-2 mb-4 header">
    <div class="row">
      <div class="col">
        <div class="div" style="text-align: center">
          <img
            class="logoImage"
            src="../../assets/logos/pocketmartNavbar.png"
            alt="Logo"
          />
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col m-2 pe-0">
        <button
          type="button"
          [ngClass]="{
            'active-button': isActive,
            'inactive-button': !isActive
          }"
          class="btn btn-success topButton"
          (click)="active()"
        >
          Active
        </button>
      </div>
      <div class="col m-2 ps-0">
        <button
          type="button"
          class="btn btn-success topButton"
          [ngClass]="{
            'active-button': !isActive,
            'inactive-button': isActive
          }"
          (click)="inActive()"
        >
          Inactive
        </button>
      </div>
    </div>
  </div>

  <br /><br /><br />

  <!-- <hr style="margin: 0" /> -->

  <!--modal window to add sale-->
  <div class="container">
    <div class="row">
      <div class="col m-4">
        <div class="modal-overlay" *ngIf="isModalOpen" (click)="closeModal()">
          <div class="modal-content" (click)="$event.stopPropagation()">
            <button class="close-button" (click)="closeModal()">x</button>
            <h2>Add Sale</h2>
            <p style="color: gray;">(Please add your quantity of sales)</p>
            <!-- <label for="productTitle">Product title:</label>
            <p>
              <b>{{ productTitle }}</b>
            </p> -->

            <label style="font-size: larger;" for="productTitle">Selling price:</label>
            <p>
              <b><i class="fa-solid fa-indian-rupee-sign"></i> {{ price }}</b>
            </p>

            <div class="row">
              <div class="col-4 pe-0">
                <label style="margin-top: 7px;" for="productTitle">Items sold:</label>
              </div>
              <div class="col">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Enter quantity"
                  aria-label="Number input"
                  aria-describedby="basic-addon1"
                  [(ngModel)]="quantity"
                  (input)="limitDigits($event)"
                  min="0"
                  max="999"
                  [attr.maxlength]="3"
                />
              </div>
            </div>

            <button
              style="width: 100%; margin-top: 15px"
              type="button"
              class="btn btn-primary addSale"
              (click)="toAddSale()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--------------------------->

  <div class="container mainBody pt-4">
    <div class="row" *ngIf="list && list.length == 0">
      <div class="col">
        <h5>Nothing to show</h5>
      </div>
    </div>

    <div class="div" style="text-align: center" *ngIf="spinner">
      <button class="btn spinnerColor" type="button" disabled>
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Loading...
      </button>
    </div>
    <div class="div mb-4" *ngIf="!spinner">
      <div class="row row-card mb-2" *ngFor="let list of list">
        <div class="row p-0">
          <div class="col">
            <span
              *ngIf="!list.approvalStatus && !isProductRejected(list.rejectStatus)"
              class="badge rounded-pill text-bg-danger"
              >Not Approved</span
            >
            <span *ngIf="isProductRejected(list.rejectStatus)" class="badge rounded-pill bg-danger">
              {{ getReadableStatus(list.rejectStatus) }}
            </span>
            <span
              *ngIf="list.approvalStatus"
              class="badge rounded-pill text-bg-success"
              >Approved</span
            >
          </div>
          <div class="col p-0">
            <button
              (click)="editProduct(list.productID)"
              style="float: right"
              type="button"
              class="btn btn-success editButton"
            >
              <i class="fa-solid fa-pen-to-square"></i>
            </button>
          </div>
        </div>

        <div class="col-4 colImage">
          <img
            *ngIf="!list.productThumbNailImageURL"
            class="cardImage"
            src="../../assets/defaultImage/defaultimage.jpg"
            alt="default image"
          />

          <img
            *ngIf="list.productThumbNailImageURL"
            class="cardImage"
            [src]="list.productThumbNailImageURL"
            alt="default image"
          />
          <button
            *ngIf="list.instock"
            style="width: 100%; margin-top: 5px"
            [disabled]="!list.approvalStatus"
            type="button"
            class="btn btn-primary addSale"
            (click)="
              addSale(list.productID, list.productName, list.sellingPrice)
            "
          >
            Sale <span>&nbsp;<i class="fa-solid fa-plus" style="color: #ffffff;"></i></span> 
          </button>
        </div>
        <div class="col description ps-4">
          <div class="row">
            <div class="col"></div>
          </div>
          <div class="row row-ProductName">
            <b class="truncate">{{ list.productName }}</b>
          </div>
          <div class="row mt-1">
            <div class="col">
              {{
                list.productDescription.length > 15
                  ? (list.productDescription | slice : 0 : 15) + "..."
                  : list.productDescription
              }}
            </div>
          </div>
          <hr style="margin: 10px" />
          <div class="row">
            <div class="col">
              <i class="fa-solid fa-indian-rupee-sign"></i>
              {{ list.sellingPrice }}
            </div>
            <div class="col">Views: {{ list.views || 0 }}</div>
          </div>

          <div class="row mt-3 p-0">
            <p
              *ngIf="list.verifiedStatus"
              style="margin-bottom: 0; color: green; font-size: 14px"
            >
              <b *ngIf="!list.approvalStatus">Verified by district admin</b>
            </p>

            <p
              *ngIf="!list.verifiedStatus"
              style="margin-bottom: 0; color: rgb(219, 66, 66); font-size: 14px"
            >
              <b>Pending verification by district admin</b>
            </p>
          </div>
        </div>
      </div>

      <div class="div" *ngIf="list && list.length > 0">
        <div
          class="pagination-controls"
          *ngIf="!spinner && listType === 'active'"
        >
          <button
            (click)="previousPageActive()"
            [disabled]="currentPageActive === 1"
          >
            Previous
          </button>
          <span>Page {{ currentPageActive }} of {{ totalPagesActive }}</span>
          <button
            (click)="nextPageActive()"
            [disabled]="currentPageActive === totalPagesActive"
          >
            Next
          </button>
        </div>
      </div>

      <div class="div" *ngIf="list && list.length > 0">
        <div
          class="pagination-controls"
          *ngIf="!spinner && listType === 'inactive'"
        >
          <button
            (click)="previousPageInactive()"
            [disabled]="currentPageInactive === 1"
          >
            Previous
          </button>
          <span
            >Page {{ currentPageInactive }} of {{ totalPagesInactive }}</span
          >
          <button
            (click)="nextPageInactive()"
            [disabled]="currentPageInactive === totalPagesInactive"
          >
            Next
          </button>
        </div>
      </div>
    </div>

    <br /><br /><br /><br />
  </div>
</div>
<app-nav-bar></app-nav-bar>
